// maxAge: 7776000 is equal to expiring after 90 days

import * as actionTypes from '../../actions';
import store from 'store';
import Cookies from 'universal-cookie';

const cookies = new Cookies();


export const setSelectedDivision = (division) => {

    if(division === 'all') {
        cookies.set('archk-selected-division', '', { path: '/', maxAge: 7776000 });
        return   store.dispatch({
            type: actionTypes.SET_STATE,
            payload: { selected_division: {} },
        });
    }

    const division_id = division ? division : cookies.get('archk-selected-division')
    const divisions = store.getState().divisions;
    const foundDivision = divisions.find(d => d._id === division_id);

    if(foundDivision) cookies.set('archk-selected-division', foundDivision._id, { path: '/', maxAge: 7776000 });

    store.dispatch({
        type: actionTypes.SET_STATE,
        payload: { selected_division: foundDivision ? foundDivision  : {} },
    });

}


export const removeEmailEditor = (uuid) => {
    store.dispatch({
        type: actionTypes.REMOVE_EMAIL_EDITOR,
        payload: uuid
    });
}

export const setOnCall = (params) => {

    document.body.classList.add('archk-bg-on-call')

    store.dispatch({
        type: actionTypes.SET_STATE,
        payload: {
            on_call: {
                _id: params._id || 'test'
            }
        }
    });
}

export const setOffCall = () => {
    document.body.classList.remove('archk-bg-on-call')

    store.dispatch({
        type: actionTypes.SET_STATE,
        payload: {
            on_call: {}
        }
    });
}
