import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Modal, Row, Col, Card, CardHeader, FormGroup, Input, CardTitle } from "reactstrap";
import { useCallback } from 'react';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import SearchCollections from "components/system/Search/Collections";
import Circle from 'components/markup/loading/Circle';
import DatePicker from 'components/markup/inputs/Day';
import ObjectFinder from 'components/system/Objects/Finder';

import { openDocumentCenter } from 'store/functions/document_center';
import { onDownloadDocument } from 'utils/documents';

import api from 'api'
import moment from 'moment'

import { toast } from 'react-toastify'

import UserComments from 'components/system/UserComments';
import SearchCreditors from '../../Creditors/Search';

const ModalObjections = ({showModal, toggleModal, onSuccess, objection_id, fetchInfo}) => {

    const [err, setErr] = useState(false)
    const [objection, setObjection] = useState(null)

    const onInputChange = useCallback((field, val) => {
        const _objection = JSON.parse(JSON.stringify(objection));
        _objection[field] = val;
        setObjection(_objection)
    }, [objection])

    const onUpload = useCallback(async (obj) => {
        const result = await api.modules.bk.objections.documents.add(objection._id, { document: obj._id })
        if(result.data) {
            const newObj = JSON.parse(JSON.stringify(objection))
            newObj.documents = result.data.documents;
            setObjection(newObj)
        }
    }, [objection])
   
    const onRemoveUpload = useCallback(async (id) => {
        const result = await api.modules.bk.objections.documents.delete(objection._id, { document: id })
        if(result.data) {
            const newObj = JSON.parse(JSON.stringify(objection))
            newObj.documents = result.data.documents;
            setObjection(newObj)
        }
    }, [objection])

    const onSave = useCallback(async () => {
        if(!objection.name) return toast.info(`A name must be set before updating a objection`);
        if(objection.use_creditor_friendly_name && !objection.creditor_friendly_name) return toast.info(`You must set a name for the creditor is adding from the objection itself.`)

        const result = await api.modules.bk.objections.update(objection._id, objection)

        if(!result.success) return toast.error(`Something went wrong updating this objection, please refresh your page`)
        
        toast.success(`Objection updated successfully`);
        toggleModal()
        fetchInfo()
        if(onSuccess) onSuccess(result.data)

    }, [fetchInfo, objection, onSuccess, toggleModal])

    const fetchData = useCallback(async () => {
        if(showModal) {
            const result = await api.modules.bk.objections.findById(objection_id);
            if(!result.success) return setErr(true);
            setObjection(result.data)
        } else {
            setErr(false)
        }
    }, [objection_id, showModal])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
            zIndex={2000}
        >

            <div className="modal-header">
                <h5 className="modal-title">Edit An Objection</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            {err ? (
                <div className="modal-body">
                    <div className='alert alert-danger mb-0'>Something went wrong loading this objection, please refresh your page.</div>
                </div>
            ) : !objection ? (
                <div className="modal-body">
                    <Circle />
                </div>
            )  : (
                <div>

                    <div className="modal-body border-bottom">

                        <h2 className='text-uppercase text-cente mb-0 pb-3 pt-3'>Status / Amount</h2>

                        <FormGroup>
                            <label className='form-control-label'>Objection Name</label>
                            <Input 
                                type="text"
                                value={objection.name}
                                onChange={(e) => onInputChange('name', e.target.value)}
                            />
                        </FormGroup>

                         {objection.use_creditor_friendly_name ? (
                            <FormGroup>
                                <label className='form-control-label'>Creditor Name</label>
                                <Input 
                                    type="text"
                                    value={objection.creditor_friendly_name}
                                    onChange={(e) => onInputChange('creditor_friendly_name', e.target.value)}
                                />
                            </FormGroup>
                        ) : (
                            <SearchCreditors 
                                title="Associated Creditor"
                                value={objection.creditor}
                                onChange={(obj) => onInputChange('creditor', obj.value)}
                            />
                        )}

                        <div className="custom-control custom-checkbox" >
                            <input
                                className="custom-control-input"
                                id="archk-bk-creditor-friendly-name"
                                type="checkbox"
                                checked={objection.use_creditor_friendly_name}
                                onChange={(e) => onInputChange('use_creditor_friendly_name', !objection.use_creditor_friendly_name)}
                            />
                            <label className="custom-control-label" htmlFor="archk-bk-creditor-friendly-name">
                                Use Name From Objection
                            </label>
                        </div>

                        <hr />

                        <Row>
                            <Col md={4}>
                                <ReactSelect
                                    title="Objection Status"
                                    formGroup={true}
                                    onChange={(obj) => onInputChange('status', obj.value)}
                                    options={[
                                        { value: 'open', label: 'Open' },
                                        { value: 'resolved', label: 'Resolved' },
                                    ]}
                                    value={objection.status}
                                />    
                            </Col>
                            <Col md={4}>
                                <ReactSelect
                                    title="Severity"
                                    formGroup={true}
                                    onChange={(obj) => onInputChange('severity', obj.value)}
                                    options={[
                                        { value: 1, label: 'Normal' },
                                        { value: 2, label: 'Important' },
                                        { value: 3, label: 'Severe' },
                                    ]}
                                    value={objection.severity}
                                />    
                            </Col>
                            <Col md={4}>
                                <ReactSelect
                                    title="Objection Number"
                                    formGroup={true}
                                    onChange={(obj) => onInputChange('objection_number', obj.value)}
                                    options={[
                                        { value: 1, label: 1 },
                                        { value: 2, label: 2 },
                                        { value: 3, label: 3 },
                                        { value: 4, label: 4 },
                                        { value: 5, label: 5 },
                                        { value: 6, label: 6 },
                                        { value: 7, label: 7 },
                                        { value: 8, label: 8 },
                                        { value: 9, label: 9 },
                                        { value: 10, label: 10 },
                                        { value: 11, label: 11},
                                        { value: 12, label: 12},
                                        { value: 13, label: 13},
                                        { value: 14, label: 14},
                                        { value: 15, label: 15},
                                        { value: 16, label: 16},
                                        { value: 17, label: 17},
                                        { value: 18, label: 18},
                                        { value: 19, label: 19},
                                        { value: 20, label: 20},
                                    ]}
                                    value={objection.objection_number}
                                />    
                            </Col>

                        </Row>

                    </div>
                  
                    <div className="modal-body border-bottom bg-secondary">

                        <h2 className='text-uppercase text-cente mb-0 pb-3 pt-3'>Parties / Dates</h2>

                        <SearchCollections
                            collection={'contacts'}
                            title="Opposing Party"
                            placeholder="Search Contacts..."
                            value={objection.opposing_party}
                            onChange={(obj) => onInputChange('opposing_party', obj.value)}
                            filter={{}}
                        /> 

                        <Row>
                            <Col md={4}>
                                <DatePicker 
                                    title={`Objection Filed Date`}
                                    value={objection.date_filed ? moment(objection.date_filed).toDate() : ''} 
                                    onChange={day => onInputChange('date_filed', day)} 
                                />
                            </Col>
                            <Col md={4}>
                                <DatePicker 
                                    title={`Objection Document Sent To Opposing Party`}
                                    value={objection.date_document_sent ? moment(objection.date_document_sent).toDate() : ''} 
                                    onChange={day => onInputChange('date_document_sent', day)} 
                                />
                            </Col>
                            <Col md={4}>
                                <DatePicker 
                                    title={`Date Amendment Filed`}
                                    value={objection.date_amendment_filed ? moment(objection.date_amendment_filed).toDate() : ''} 
                                    onChange={day => onInputChange('date_amendment_filed', day)} 
                                />
                            </Col>

                        </Row>

                    </div>
                  
                    <div className="modal-body">

                        <h2 className='text-uppercase text-cente mb-0 pb-3 pt-3'>Linked Documents</h2>

                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col md={6} className='align-self-center'>
                                        <CardTitle className='mb-0'>Documents</CardTitle>
                                    </Col>

                                    <Col md={6} className='align-self-center text-right'>
                                        <button 
                                            onClick={() => openDocumentCenter({matter: objection.matter, onUpload, zIndex: 3000})}
                                            className='btn btn-outline-success btn-sm'
                                        >
                                            <i className="fas fa-plus mr-2" /> Add
                                        </button>
                                    </Col>
                                </Row>
                            </CardHeader>

                            <div className='table-responsive'>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>Document</th>
                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {objection.documents.length ? objection.documents.map(c => (
                                            <tr key={c}>
                                                <td>
                                                    <span className='cursor-pointer' onClick={() => onDownloadDocument(c)}>
                                                        <ObjectFinder collection="documents" _id={c} />
                                                    </span>
                                                </td>
                                                <td className='text-right'>
                                                    <button onClick={() => onRemoveUpload(c)} className='btn btn-sm btn-danger'>Remove</button>
                                                </td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td>No Documents Added</td>
                                                <td></td>
                                            </tr>
                                        )}
                                        
                                    </tbody>
                                </table>
                            </div>
                        </Card>

                        <UserComments 
                            collection_name="bk_objections" 
                            collection_id={objection._id}
                            parent_name={objection.name}
                        />

                    </div>

                </div>
            )}

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
                {!err && objection ? (
                    <button className="btn btn-success" onClick={onSave}>
                        <i className="fas fa-save mr-2" /> Save
                    </button>
                ) : ''}
            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ModalObjections);