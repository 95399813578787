import { useCallback, useEffect, useState } from 'react';

import Circle from 'components/markup/loading/Circle'

import moment from 'moment';
import api from 'api'

import reactHTMLParser from 'html-react-parser';

import downloadBkDocument from '../_utils/downloadBkDocument';


const ModuleBKDocketEntries = ({ bkCase }) => {
    const hasInfo = bkCase && bkCase.case_number && bkCase.court_code;

    const [entries, setEntries] = useState(null);
    const [err, setErr] = useState(false);

    const fetchData = useCallback(async () => {
        if(!hasInfo) return;
        const data = await api.modules.bk.docket_entries.find(bkCase.court_code, bkCase.case_number)

        if(!data.success) return setErr(`Internal Error, Please Refresh Your Page`);
        if(data.data) return setEntries(data.data)
        return setEntries([])

    }, [bkCase.case_number, bkCase.court_code, hasInfo])

    useEffect(() => {
        fetchData()
    }, [fetchData])
  
    return (
        <div>

            <h2 className='text-warning'>Docket Entries</h2>

            {!hasInfo ? (
                <div className='alert alert-info'><i className="fas fa-info-circle mr-2" /> A case must have a valid case number and court code to pull in docket entries</div>
            ) : err ? (
                <div className='alert alert-warning'>{err}</div>
            ) : !entries ? (
                <Circle />
            ) : (
                !entries.length ? (
                    <p className='text-sm'>No Docket Entries were found for this case.</p>
                ) : (
                    <div className='table-responsive border'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Filing Date</th>
                                    <th className='text-center' style={{width: 40}}>#</th>
                                    <th>Text</th>
                                </tr>
                            </thead>
                            <tbody>
                                {entries.map(entry => (
                                    <tr className='mb-5' key={entry._id}>
                                        <td style={{verticalAlign: 'top'}}>{entry.date_filed}</td>
                                        <td  style={{verticalAlign: 'top'}} className='text-center'>{entry.docket_no}</td>
                                        <td>
                                            {reactHTMLParser(entry.docket_text)}
                                            {entry.annotations && entry.annotations.length ? (
                                                <div className='border mt-3 px-3 py-3 rounded' style={{background: '#eaeaea'}}>
                                                    <h5 className='text-whit mb-3'>
                                                        <span className='border rounded bg-warning text-white px-4 py-2' style={{width: 165, display: 'inline-block'}}>
                                                            <i className="fa-solid fa-calendar" /> Important Dates
                                                        </span>
                                                    </h5>
                                                    {entry.annotations.map((d, i) => (
                                                        <div key={i}>
                                                            <div className='text-uppercase font-weight-bold'>{d.name}</div>
                                                            <p className='text-sm mb-0'>
                                                                {d.date_formatted ? moment(d.date_formatted).format('MM/DD/YYYY h:mm A') : (
                                                                    <span className='text-warning'>{d.date} - Check Timezone With Docket Entry</span>
                                                                )}
                                                                
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : ''}
                                        
                                            {entry.documents && entry.documents.length ? (
                                                <div className='border mt-3 px-3 py-3 rounded' style={{background: '#eaeaea'}}>

                                                    <h5 className='text-whit mb-3'>
                                                        <span className='border rounded bg-purple text-white px-4 py-2' style={{width: 165, display: 'inline-block'}}>
                                                            <i className="fa-solid fa-file" /> Associated Documents
                                                        </span>
                                                    </h5>
                                                    {entry.documents.map((d, i) => (
                                                        <div key={i}>
                                                            <span className='cursor-pointer text-uppercase text-dark' onClick={() => downloadBkDocument(d)}>
                                                                {d.name}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : ''}
                                            
                                            {entry.actions && entry.actions.length ? (
                                                <div className='border mt-3 px-3 py-3 rounded' style={{background: '#eaeaea'}}>
                                                    <h5 className='text-whit'>
                                                        <span className='border rounded bg-success text-white px-4 py-2' style={{width: 165, display: 'inline-block'}}>
                                                            <i className="fa-solid fa-bolt-lightning" /> Background Actions
                                                        </span>
                                                    </h5>
                                                    {entry.actions.map((d, i) => (
                                                        <div key={i}>

                                                            <div className='mt-3'>
                                                                <div className='text-uppercase text-dark'>{d.name}</div>
                                                                <div>
                                                                    <span style={{width: 100, display: 'inline-block'}}>Value Found:</span>
                                                                    {d.type === 'date' ? moment(d.value).format('MM/DD/YYYY h:mm A') : d.value}
                                                                </div>

                                                                <div>
                                                                    <span style={{width: 100, display: 'inline-block'}}>Result:</span>
                                                                    {d.result && d.result.includes('Set For:') ? (
                                                                        <span>Set For: {moment(d.result.split('Set For: ')[1]).format('MM/DD/YYYY h:mm A')} </span>
                                                                    ) : d.result}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    ))}
                                                </div>
                                            ) : ''}
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )
            )}

        </div>


    )
}

export default ModuleBKDocketEntries;


