import { useState, useCallback, useEffect, useRef, useReducer } from 'react';
import { connect } from 'react-redux';

import ReactQuill from 'react-quill';

import Toolbar from './Toolbar'
import To from './To'
import Subject from './Subject'
import { toast } from 'react-toastify'
import Circle from 'components/markup/loading/Circle'

import formatText from 'utils/formatText'

import api from 'api'

function reducer(state, action) {
    const current = JSON.parse(JSON.stringify(state))

    if(action.type === 'add') {
        return [...current, ...action.payload]
    } else if(action.type === 'update') {
        let index = current.findIndex(i => i.id === action.payload.id)
        current[index].status = action.payload.status
        current[index].data = action.payload.data
        return current;
    } else if(action.type === 'delete') {
        let index = current.findIndex(i => i.id === action.payload)
        current.splice(index, 1)
        return current;
    }

    return []
}

const SendEmail = ({ matter, thread, contact, task, emailTemplate, division, onSent, defaultBody, toggleModal, viewing_user, selected_division }) => {

    const ref = useRef(null)
    const [attachments, setAttachments] = useReducer(reducer, []);

    const [loadingValues, setLoadingValues] = useState(false)
    const [sending, setSending] = useState(false)
    const [foundMatter, setFoundMatter] = useState(null)
    const [foundEmailTemplate, setFoundEmailTemplate] = useState(null)
    const [foundThread, setFoundThread] = useState(null)
    const [from, setFrom] = useState(null)
    const [replyToId, setReplyToId] = useState(null)

    const [err, setErr] = useState(false)
    const [to, setTo] = useState([])
    const [subject, setSubject] = useState('')
    const [body, setBody] = useState(defaultBody ? defaultBody : '')
    const [editingRecipients, setEditingRecipients] = useState(false)
    const onToChange = useCallback((_to) => {

        let contacts = JSON.parse(JSON.stringify(_to));
      
        let errs = [];

        contacts.forEach(c => {
            if(!c.email) errs.push(c)
        })

        if(errs.length) {
            contacts = contacts.filter(c => !errs.some(cc => c._id === cc._id))
            toast.info('Not all selected recipients have a valid email address on file, one or more recipients have been removed.', { autoClose: 7500 })
        }

        setTo(contacts);
        
    }, [])
    

    const onSelectTemplate = useCallback(() => {
        // setAttachments([])
    }, [])

    const onSend = useCallback(async () => {

        const formattedTo = to.map(t => {
            const email = t.selected_email ? t.selected_email : t.email;
            const name = t.display_name ? t.display_name : t.name ? t.name : email;
            return { name, email, _id: t._id }
        })

        const formattedAttachments = attachments.map(a => {
            return {
                content: a.data,
                content_type: a.type,
                filename: a.name,
                is_inline: false,
                size: a.size
            }
        })

        const data = {
            from: from.email,
            to: formattedTo,
            attachments: formattedAttachments,
            subject,
            body,
            task,
            matter: foundMatter ? foundMatter._id : undefined,
            email_template: foundEmailTemplate ? foundEmailTemplate._id : undefined,
            reply_to_message_id: replyToId
        }
        const value = body ? formatText(body).stripHTML() : ''

        if(!to.length) return toast.info('At least one recipient needs to be specified to send an email.')
        if(!value.trim().length) return toast.info('The body of the email cannot be blank.')
        if(!subject) return toast.info('Please enter the subject of this email.')
        if(subject.length < 4) return toast.info('The subject of this email must be 4 characters or more.')

        let size = 0;

        data.attachments.forEach(a => size += a.size)
        if(size > 1000000 * 3) return toast.info(`Currently IntuiDesk support sending up to 3MB of attachments with each email, to send larger files send the email directly from your email provider.`)

        setSending(true)
        const sent = await api.emails.send(data)
        setSending(false)

        if(!sent.success) return toast.error(`Email failed, to send. Please try again.`)
        onSent(sent.data)
        if(toggleModal) toggleModal()

    }, [to, subject, from, body, attachments, task, foundMatter, foundEmailTemplate, onSent, replyToId, toggleModal])
    
    const onTemplateChange = useCallback(async (template, matter, thread) => {
        const override = viewing_user.division_overrides.find(d => d.division === division);

        if(template.body && matter) {
            setLoadingValues(true)
            const values = await Promise.all([
                api.inject_values.string({
                    string: template.body,
                    matter: matter ? matter._id : undefined,
                    hideErrors: false,
                    randomize: template.randomize_body ? true : false,
                }),
                api.inject_values.string({
                    string: template.subject,
                    matter: matter ? matter._id : undefined,
                    hideErrors: false,
                    randomize: template.randomize_subject ? true : false,
                })
            ])
            setLoadingValues(false)
            let body = values[0].data ? values[0].data : template.body;
            if(override && override.email_signature) body += override.email_signature

            setSubject(foundThread && foundThread.subject ? foundThread.subject : values[1].data ? values[1].data : template.subject)
            setAttachments(template.attachments)
            setFoundEmailTemplate(template)
            return setBody(body)
        } else {
            const found = await api.email_templates.findById(template)
            if(!found.data) return setErr("Template could not be found, try refreshing your page.")

            setLoadingValues(true)
            console.log(found.data)
            const values = await Promise.all([
                api.inject_values.string({
                    string: found.data.body,
                    matter: matter ? matter._id : undefined,
                    hideErrors: false,
                    randomize: found.data.randomize_body ? true : false,
                }),
                api.inject_values.string({
                    string: found.data.subject,
                    matter: matter ? matter._id : undefined,
                    hideErrors: false,
                    randomize: found.data.randomize_subject ? true : false,
                })
            ])
            setLoadingValues(false)

            let body = values[0].data ? values[0].data : found.data.body
            if(override && override.email_signature) body += override.email_signature
            
            setFoundEmailTemplate(found.data)
            setAttachments(found.data.attachments)
            setSubject(foundThread && foundThread.subject ? foundThread.subject : values[1].data ? values[1].data : found.data.subject)
            return setBody(body)
        }

    }, [division, foundThread, viewing_user.division_overrides])

    const fetchData = useCallback(async () => {
        // matter, thread, task, emailTemplate, onSent, defaultBody, viewing_user
        const data = await api.emails.compose({
            division        : division ? division : selected_division._id,
            matter          : matter,
            contact         : contact,
            email_thread    : thread,
            email_template  : emailTemplate,
            task            : task,
        })
        if(!data.success) return setErr(data.message)

        let body = data.data.body ? data.data.body : ''

        const override = viewing_user.division_overrides.find(d => d.division === division);
        if(override && override.email_signature) {
            body += '<br /><br /> '
            body += override.email_signature
        }

        setFrom(data.data.from[0])
        setTo(data.data.to)
        setSubject(data.data.subject)

        if(data.data.reply_to_message_id) setReplyToId(data.data.reply_to_message_id)
        if(data.data.email_template) setFoundEmailTemplate(data.data.email_template)
        if(data.data.email_template && data.data.email_template.attachments) setAttachments(data.data.email_template.attachments)
        if(data.data.matter) setFoundMatter(data.data.matter)
        if(data.data.email_thread) setFoundThread(data.data.email_thread)
        if(body) setBody(body)

    }, [matter, thread, contact, task, emailTemplate, division, viewing_user, selected_division._id])

    // const fetchData = useCallback(async () => {

    //     let promiseArr = [];

    //     if(matter) {
    //         promiseArr.push(api.matters.overview(matter));
    //     }
    //     if(thread) {
    //         promiseArr.push(api.email_threads.findById(thread));
    //     }
    //     if(emailTemplate) {
    //         promiseArr.push(api.email_templates.findById(emailTemplate))
    //     }
    //     if(task) {
    //         promiseArr.push(api.tasks.findById(task))
    //         promiseArr.push(api.tasks.recipients(task))
    //     }

    //     const values = await Promise.all(promiseArr);

    //     let _matter;
    //     let _thread;

    //     if(matter) _matter = values[0] ? values[0].data : null;
    //     if(thread) _thread = values[0] ? values[0].data : null;

    //     const _template     = values[1] ? values[1].data : null
    //     const _task         = values[2] ? values[2].data : null
    //     const _recipients   = values[3] ? values[3].data : null

    //     console.log('_matter')
    //     console.log(_thread)

    //     // last email in the email array

    //     if(!_matter && !_thread) return setErr('Matter or thread could not be found, try refreshing your page and try again')

    //     if(_matter) setFoundMatter(_matter)
    //     if(thread)  setFoundThread(_thread.thread)

    //     if(_template) onTemplateChange(_template, _matter)


    //     if(_task && _matter) {
    //         try {

    //             // set to based on task contacts
    //             if(_recipients && _recipients.length) return setTo(_recipients)

    //             // else try and set to based on assigned contacts on the task if none are specified
    //             const task = _task.workflow_step.tasks.find(t => t._id === _task.workflow_task)
    //             if(task && task.assigned_contacts && task.assigned_contacts.length) {
    //                 let parties = []

    //                 task.assigned_contacts.forEach(c => {
    //                     const foundParty = _matter.parties.find(p => p.workflow_contact._id === c)
    //                     if(foundParty && foundParty.contact) parties.push(foundParty.contact)
    //                 })

    //                 setTo(parties)
    //             } 
    //         } catch(e) {}
    //     }  else {
    //         // set recipients based on the contacts on the matter
    //         if(_matter) {
    //             setTo(_matter.contacts)
    //         } else {

    //             const lastEmail = _thread.emails[_thread.emails.length - 1]
    //             const allParties = lastEmail.cc.concat(lastEmail.to).concat(lastEmail.from);

    //             const override = viewing_user.division_overrides.find(d => d.division === lastEmail.division)
    //             const emailOverride = override ? override.email : null;

    //             const recipients = allParties.filter(d => d.email !== emailOverride)

    //             setTo(recipients)
    //         }
    //     }
    // }, [matter, thread, viewing_user.division_overrides, emailTemplate, onTemplateChange, task])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        err ? (
            <div className='alert alert-warning mb-0'>{err}</div>
        ) : !foundMatter && !foundThread ? (
            <Circle />
        )  : (
            <div className="archk-send-email">

                <To
                    editingRecipients={editingRecipients}
                    setEditingRecipients={setEditingRecipients}
                    onToChange={onToChange}
                    to={to}
                    foundMatter={foundMatter}
                    foundThread={foundThread}
                />

                <Subject 
                    subject={subject}
                    setSubject={setSubject}
                    setEditingRecipients={setEditingRecipients}
                />

                <div className='position-relative'>
                    {loadingValues ? <div style={styles.loadingContainer}><Circle /></div> : ''}
                    <ReactQuill 
                        id="archk-email-body"
                        defaultValue={body}
                        value={body}
                        ref={ref}
                        onChange={(value) => setBody(value)} 
                        theme="snow"
                        modules={{ toolbar: '#toolbar' }}
                        onFocus={() => setEditingRecipients(false)}
                    />
                </div>
            
               
                <Toolbar
                    attachments={attachments}
                    setAttachments={setAttachments}
                    onSend={onSend}
                    onSelectTemplate={onSelectTemplate}
                    emailTemplate={emailTemplate}
                    foundMatter={foundMatter}
                    foundThread={foundThread}
                    sending={sending}
                    onTemplateChange={onTemplateChange}
                />

                {/* <button  onClick={onSend} style={{position: 'absolute', bottom: 10, right: 10}} className="btn btn-sm btn-outline-success">
                    <i className="fas fa-plus " /> Add Note
                </button> */}
            </div>
        )
    )
}

const styles = {
    loadingContainer: {
        position: 'absolute', 
        top: 0, 
        left: 0, 
        right: 0, 
        bottom: 0, background: 'rgba(0,0,0,.2)',
        zIndex: 100,
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center'
    }
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
        selected_division: state.state.selected_division,
    };
};

export default connect(mapStateToProps, '')(SendEmail);