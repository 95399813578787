import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';


import ModalToggler from 'components/functional/modals/Toggler';
import Circle from 'components/markup/loading/Circle';


import Edit from 'views/dashboard/pages/matters/View/Main/OutstandingItems/Edit';
import ItemList from 'views/dashboard/pages/matters/View/Main/OutstandingItems/ItemList';
import InfoBanner from '../_components/InfoBanner';

const ModuleBKOverview = ({ bkCase, onMoveStage, items, fetchItems, stage }) => {

    const itemsPending = items ? items.filter(i => !i.finished_at) : null
    const itemsFinished = items ? items.filter(i => i.finished_at) : null

    if(!itemsPending && !itemsFinished) return <Circle />
  
    return (
        <div>

            <div className={`mb-3 rounde text-center border py-3 px-4 text-capitalize bg-gradient-${itemsPending && itemsPending.length ? 'danger' : 'success'}`}>
                <h2 className='text-white'>Case Deadlines</h2>
            </div>

            <InfoBanner bkCase={bkCase} />

            <hr />

            <ItemList 
                onItemChange={fetchItems} 
                modalIndex={2500} 
                items={itemsPending} 
                finished={false} 
                hideAddNew={true} 
                cardHeader={(
                    <Row>
                        <Col md={6} className="align-self-center">
                            <h2 className='text-warning '>Unfinished Deadlines</h2>
                        </Col>
                        <Col md={6} className="align-self-center text-right">
                            {/* <ModalToggler 
                                title="Edit A Deadline"
                                findTasks={fetchItems} 
                                component={Edit} 
                                mod={'bk'}
                                metadata={{ stage: stage }}
                                modalIndex={2000}
                                defaultIsDeadline={true}
                            >
                                <button className='btn btn-outline-warning  btn-sm'>
                                    Add Admin Deadline <i className="fas fa-plus ml-2" />
                                </button>
                            </ModalToggler> */}
                            <span className='pl-4'>

                            <ModalToggler 
                                title="Edit A Deadline"
                                findTasks={fetchItems} 
                                component={Edit} 
                                mod={'bk'}
                                metadata={{ stage: stage }}
                                modalIndex={2000}
                                defaultIsDeadline={true}
                                defaultLockTitles={true}
                                itemTypes={[
                                    // {
                                    //     label: "Follow Up",
                                    //     options: [
                                    //         { value: 'Follow up with CLIENT(s)', label: 'Follow up with CLIENT(s)' },
                                    //         { value: 'Follow up with TRUSTEE', label: 'Follow up with TRUSTEE' },
                                    //         { value: 'Follow up with CREDITOR', label: 'Follow up with CREDITOR' },
                                    //         { value: 'Follow up with STAFF ATTORNEY', label: 'Follow up with STAFF ATTORNEY' },
                                    //         { value: 'Follow up OTHER', label: 'Follow up OTHER' },
                                    //     ]
                                    // },
                                    {
                                        label: "Drop Dead",
                                        options: [
                                            { value: 'Drop Dead: Provide Documents to Trustee', label: 'Drop Dead: Provide Documents to Trustee', is_deadline: true },
                                            { value: 'Drop Dead: Provide Documents to Creditor', label: 'Drop Dead: Provide Documents to Creditor', is_deadline: true },
                                            { value: 'Drop Dead: File Amended Plan/Schedules', label: 'Drop Dead: File Amended Plan/Schedules', is_deadline: true },
                                            { value: 'Drop Dead: File Declaration/Affidavit/Other', label: 'Drop Dead: File Declaration/Affidavit/Other', is_deadline: true },
                                            { value: 'Drop Dead: Increase Pay History', label: 'Drop Dead: Increase Pay History', is_deadline: true },
                                        ]
                                    },
                                    {
                                        label: "Preparation",
                                        options: [
                                            { value: 'File Response to Motion', label: 'File Response to Motion', is_deadline: true },
                                        ]
                                    },
                                    {
                                        label: "Filing",
                                        options: [
                                            { value: 'File Amended Plan/Schedules', label: 'File Amended Plan/Schedules', is_deadline: true },
                                        ]
                                    },
                                    {
                                        label: "Review",
                                        options: [
                                            { value: 'Review Secured Claim', label: 'Review Secured Claim', is_deadline: true },
                                            { value: 'Review Priority Claim', label: 'Review Priority Claim', is_deadline: true },
                                            { value: 'Review Notice of Unfiled Tax Returns', label: 'Review Notice of Unfiled Tax Returns', is_deadline: true },
                                            { value: 'Review Objection to Confirmation', label: 'Review Objection to Confirmation', is_deadline: true },
                                            { value: 'Review Plan Payment Change/Submit Payment order', label: 'Review Plan Payment Change/Submit Payment order', is_deadline: true },
                                            { value: 'Review Order Adjourning', label: 'Review Order Adjourning', is_deadline: true },
                                            { value: '*Review Deficiency Notice', label: '*Review Deficiency Notice', is_deadline: true },
                                        ]
                                    },
                                    {
                                        label: "Documents",
                                        options: [
                                            { value: 'Provide Documents to Trustee', label: 'Provide Documents to Trustee', is_deadline: true },
                                            { value: 'Provide Documents to Creditor', label: 'Provide Documents to Creditor', is_deadline: true },
                                        ]
                                    },
                                    {
                                        label: "Objections & Motions",
                                        options: [
                                            { value: 'File Certificate of Non Response', label: 'File Certificate of Non Response', is_deadline: true },
                                            { value: 'File Motion to Extend/Impose Stay', label: 'File Motion to Extend/Impose Stay', is_deadline: true },
                                        ]
                                    },
                                    

                                ]}
                            >
                                <button className='btn btn-outline-success  btn-sm'>
                                    Add Deadline <i className="fas fa-plus ml-2" />
                                </button>
                            </ModalToggler>
                            </span>

                        </Col>
                    </Row>
                )}
            />



        </div>


    )
}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(ModuleBKOverview);


