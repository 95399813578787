// call_queue_entries

import { useCallback, useEffect, useState } from 'react'

import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';

import NameCircle from 'components/markup/misc/NameCircle';
import formatText from 'utils/formatText'

import { getInitials } from 'utils/names'

import StandardPortal from 'components/functional/portals/Standard'

import { playSound } from 'store/functions/call_center'

import api from 'api'

import { dialNumber, enableDialing } from 'store/functions/call_center'



const CallsOnCall = ({call_center, viewing_user, socket}) => {

    const [ringing, setRinging] = useState(false);
    const [isInQueue, setIsInQueue] = useState(viewing_user.agent_is_in_queue);
    const [queue, setQueue] = useState(null);
    const [cancelled, setCancelled] = useState(null);

    const ready = call_center.browser_dialing === 'ready';
    const incomingCall = call_center.incoming_call.number ? true : false;
    const currentCall = call_center.current_call.number ? true : false;

    const canAccept = ready && isInQueue && !currentCall && !incomingCall ? true : false;

    const onDecline = useCallback(async (_queue) => {
        setCancelled(_queue._id)
        const data = await api.call_offers.decline(_queue.call)
        if(!data.success) console.log(data)
    }, [])

    const getCityAndState = useCallback(() => {
        let str = '';
        if(queue.contact.city) str = queue.contact.city;
        if(queue.contact.state) str = str ? str + ', ' + queue.contact.state : queue.contact.state

        return str;
    }, [queue])

    const onAcceptCall = useCallback(() => {
        setCancelled(queue._id)
        setQueue(null)
        dialNumber({
            name: queue.contact.display_name ? queue.contact.display_name : 'Unknown Caller',
            address: getCityAndState(),
            number: queue.contact.phone,
            contact: queue.contact._id,
            division: queue.division._id,
            matter: queue.matter,
            call_queue_entry: queue._id 
        })
    }, [queue, getCityAndState])

    const onQueueIncoming = useCallback((queueEntry) => {
        if(queueEntry && canAccept) {
            setQueue(queueEntry)
            playSound()
        }
    }, [canAccept])

    const checkQueue = useCallback(async () => {
        setQueue(null)
        const data = await api.call_queue_entries.check();
        if(data.data) onQueueIncoming(data.data)
    }, [onQueueIncoming])

    const onUpdate = useCallback((data) => {
        if(data._id === viewing_user._id) {
            setIsInQueue(data.agent_is_in_queue)
        }
    }, [viewing_user._id])
    
    const onQueueCreated = useCallback(() => {
        if(canAccept && !ringing) checkQueue()
    }, [canAccept, checkQueue, ringing])
    
    const onQueueTaken = useCallback((takenQueue) => {
        if(canAccept && queue && queue._id === takenQueue._id) {
            checkQueue()
        }
    }, [canAccept, checkQueue, queue])
   
    const onQueueDeleted = useCallback((takenQueue) => {
        if(canAccept && queue && queue._id === takenQueue._id) {
            checkQueue()
        }
    }, [canAccept, checkQueue, queue])
    
    useEffect(() => {
        if(canAccept) checkQueue()
    }, [canAccept, checkQueue])
   
    useEffect(() => {
        let interval;

        if(canAccept && queue && queue._id !== cancelled) {
            setRinging(true)
            interval = setInterval(playSound, 2500) 
        }

        return () => {
            if(interval) {
                clearInterval(interval)
                setRinging(false)
            }
        }
    }, [canAccept, queue, cancelled])

    useEffect(() => {
        if(!canAccept) setCancelled(null)
    }, [canAccept])

    useEffect(() => {
        socket.on('USERS.UPDATED', onUpdate)
        socket.on('CALL_CENTER.CALL_QUEUE_CREATED', onQueueCreated)
        socket.on('CALL_CENTER.CALL_QUEUE_TAKEN', onQueueTaken)
        socket.on('CALL_CENTER.CALL_QUEUE_DELETED', onQueueDeleted);

        return () => {
            socket.off('USERS.UPDATED', onUpdate);
            socket.off('CALL_CENTER.CALL_QUEUE_CREATED', onQueueCreated);
            socket.off('CALL_CENTER.CALL_QUEUE_TAKEN', onQueueTaken);
            socket.off('CALL_CENTER.CALL_QUEUE_DELETED', onQueueDeleted);
        }
    }, [onUpdate, socket, onQueueCreated, onQueueTaken, onQueueDeleted])



    if(!canAccept || !queue) return <></>;
    if(queue && queue._id === cancelled) return <></>;

   return (
    <StandardPortal>
         <div className='archk-queue-controller'>

            <div className='archk-queue-controller-container z-depth-5 rounded'>

                <div className='archk-queue-controller-pulse' />

                <div className='archk-queue-controller-wrapper'>

                    <div 
                        style={queue.queue_entry_color ? { background:  queue.queue_entry_color} : {}} 
                        className={`archk-queue-controller-header ${!queue.queue_entry_color ? 'bg-gradient-info' : ''}`}
                    >
                        <div className='px-3'>
                        <h4 className='my-0 py-3 text-white'>{queue.queue_entry_title ? queue.queue_entry_title : 'Incoming Queue Call'} </h4>
                        </div>
                    </div>

                    <div className='archk-queue-controller-body bg-white'>
                        <h2 className='mb-0 mt-0  font-weight-bold'>
                            {queue.contact.display_name ? queue.contact.display_name.length > 20 ? queue.contact.display_name.slice(0, 20) + '...' : queue.contact.display_name : 'Unknown Caller'}
                        </h2>
                        <p className='text-sm mb-0 mt  font-weight-bold'>{formatText(queue.contact.phone).phone()}</p>
                       
                        <p className='text-sm mb-0 mt--2  font-weight-bold'>
                            {queue.contact.city || queue.contact.state ? getCityAndState() : 'Unknown Location'}
                        </p>
                    </div>

                    <div className='archk-queue-controller-info'>
        
                        <p className='text-sm mb-0 mt-0 font-weight-bold'>
                            <span>Division:</span> {queue.division.name}
                        </p>
                        {/* <p className='text-sm mb-0 mt--2 font-weight-bold'>
                            <span>Existing Matter:</span> {queue.matter ? <b className='text-success'>Yes</b> : <b className='text-danger'>No</b>}
                        </p>
                        <p className='text-sm mb-0 mt--2 font-weight-bold'>
                            <span>Priority:</span> {queue.priority}
                        </p> */}
                        <p className='text-sm mb-0 mt--2 font-weight-bold'>
                            <span>Type:</span> {queue.type === 'hold' ? 'On Hold' : "Call Back Requested"}
                        </p>
                    </div>
                
                
                    <div className='archk-queue-controller-footer text-center archk-call pt-0 pb-3  border-bottm bg-white border-top' style={{overflow: 'hidden'}}>
                        <div className="archk-on-call-wapper archk-call-buttons text-centr   mt-4 mb-2 px-4">
                            <Row>

                                <Col md={6} className='text-center px-2'>
                                    <button onClick={() => onDecline(queue)}   className='btn btn-block btn-sm btn-danger' >
                                        Decline 
                                    </button>                           
                                </Col>

                                <Col md={6} className='text-center px-2'>
                                    <button  onClick={onAcceptCall} className='btn btn-block btn-sm btn-success'>
                                        Accept 
                                    </button>
                                </Col>

                            </Row>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </StandardPortal>
       
   )

}


const mapStateToProps = state => {
	return {
        call_center: state.call_center,
        viewing_user: state.auth.viewing_user,
        socket: state.socket,

	};
};

export default connect(mapStateToProps, '')(CallsOnCall);
