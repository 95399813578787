import { connect } from 'react-redux';
import { Card, CardFooter, CardHeader, CardTitle, FormGroup, Input, Row, Col } from 'reactstrap';

import StateInput from 'components/markup/inputs/States';

const ModuleBKOverview = ({ data, onInputChange }) => {
    return (
        <div>
              <Card>
                <CardHeader>
                    <CardTitle className="mb-0 text-uppercase">Associated Court</CardTitle>
                </CardHeader>

                <CardFooter> 

                    <FormGroup>
                        <label className='form-control-label'>Court Name</label>
                        <Input 
                            type="text"
                            value={data.court_name}
                            onChange={e => onInputChange('court_name', e.target.value)}
                        />
                    </FormGroup>
                </CardFooter>

                <CardFooter> 
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <label className='form-control-label'>Address Line 1</label>
                                <Input 
                                    type="text"
                                    value={data.court_address_line_1}
                                    onChange={e => onInputChange('court_address_line_1', e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <label className='form-control-label'>Address Line 2</label>
                                <Input 
                                    type="text"
                                    value={data.court_address_line_2}
                                    onChange={e => onInputChange('court_address_line_2', e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <label className='form-control-label'>City</label>
                                <Input 
                                    type="text"
                                    value={data.court_city}
                                    onChange={e => onInputChange('court_city', e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <StateInput 
                                title="State"
                                placeholder=" "
                                value={data.court_state}
                                onChange={state => onInputChange('court_state', state)}
                                />
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <label className='form-control-label'>Zip</label>
                                <Input 
                                    type="text"
                                    value={data.court_postal_code}
                                    onChange={e => onInputChange('court_postal_code', e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>


                </CardFooter>

            </Card>
        </div>


    )
}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(ModuleBKOverview);


