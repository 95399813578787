import { useCallback, useEffect, useState } from 'react';

import { FormGroup, Input } from 'reactstrap';


import Circle from 'components/markup/loading/Circle';
import { toast } from 'react-toastify';

import api from 'api';

import SelectTemplate from './SelectTemplate';

import ModalToggler from 'components/functional/modals/Toggler';

import To from './To';

const SendPhone = ({ matter, task, textTemplate, onSent }) => {

    const [sending, setSending] = useState(false)
    const [foundMatter, setFoundMatter] = useState(false)

    const [loadingValues, setLoadingValues] = useState(false)
    const [err, setErr] = useState(false)
    const [to, setTo] = useState(null)
    const [body, setBody] = useState('')
    const [editingRecipients, setEditingRecipients] = useState(false)

    const onToChange = useCallback((_to) => {

        if(_to && !_to.phone) {
            setTo(null)
            return toast.info('Not all selected recipients have a valid phone on file, one or more recipients have been removed.', { autoClose: 7500 })
        }

        setTo(_to);
        
    }, [])
    
 

    const onSend = useCallback(async () => {
        if(!to) return toast.info('A recipient needs to be specified to send an text message.')
        if(!body.trim().length) return toast.info('The body of the text cannot be blank.')

        setSending(true)
        const sent = await api.texts.send({
            task,
            contact     : to._id,
            to          : to.phone,
            division    : foundMatter.division,
            body        : body,
        })
        setSending(false)

        if(!sent.success) return toast.error(`Text failed, to send. Please try again.`)
        toast.success(`Text Message Successfully Sent`)
        onSent(sent.data)

    }, [to, body, task, foundMatter, onSent])
    
    const onTemplateChange = useCallback(async (_template) => {
        if(!to) return;
        setLoadingValues(true)
        const value = await api.inject_values.string({
            string: _template.body,
            randomize: _template.randomize_body ? true : false,
            contact: to._id,
            matter: matter,
            hideErrors: true
        })
        setLoadingValues(false)
        return setBody(value.data ? value.data : _template.body)
    }, [to, matter])

    const fetchData = useCallback(async () => {

        let promiseArr = [api.matters.overview(matter)];
        if(textTemplate) promiseArr.push(api.text_templates.findById(textTemplate))
        if(task) promiseArr.push(api.tasks.findById(task))

        const values = await Promise.all(promiseArr);

        const _matter   = values[0] ? values[0].data : null
        const _template = values[1] ? values[1].data : null
        const _task     = values[2] ? values[2].data : null

        if(!_matter) return setErr('Matter could not be found, try refreshing your page and try again')
        setFoundMatter(_matter)

        if(_task) {
            try {
                const foundTask = _task.workflow_step.tasks.find(t => t._id === _task.workflow_task)
                const forParty = foundTask && foundTask.assigned_contacts ? foundTask.assigned_contacts[0] : ''

                const foundParty = _matter.parties.find(r => r.workflow_contact._id === forParty)
                const contact = foundParty.contact

                const phone = contact.phone ? contact.phone : contact.phone_2 ? contact.phone_2 : contact.phone_3
                contact.phone = phone;

                setTo(contact)

                if(_template) {
                    const value = await api.inject_values.string({
                        string: _template.body,
                        contact: contact._id,
                        matter: matter,
                        hideErrors: true
                    })
            
                    return setBody(value.data ? value.data : _template.body)
                }

            } catch(e) {}
        }
    }, [matter, textTemplate, task])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        err ? (
            <div className='alert alert-warning mb-0'>{err}</div>
        ) : !foundMatter ? (
            <Circle />
        )  : (
            <div className="archk-send-email">

                <To
                    editingRecipients={editingRecipients}
                    setEditingRecipients={setEditingRecipients}
                    onToChange={onToChange}
                    to={to}
                    foundMatter={foundMatter}
                />


                <div className='position-relative'>
                    {loadingValues ? <div style={styles.loadingContainer}><Circle /></div> : ''}
                    <FormGroup className='mt-' onClick={() => setEditingRecipients(false)}>
                        <Input 
                            placeholder="..."
                            type="textarea"
                            style={{minHeight: 80}}
                            value={body}
                            onChange={(e) => setBody(e.target.value)}
                        />
                    </FormGroup>
                </div>

                <div className='position-relative' onClick={() => setEditingRecipients(false)}>
                    <button disabled={sending} onClick={onSend} className="btn btn-success send"><i className="fas fa-paper-plane mr-2" />Send</button>
                    {textTemplate ? '' : !to ? '' : (
                        <ModalToggler component={SelectTemplate} matter={matter} onTemplateChange={onTemplateChange}>
                            <button className="btn btn-outline-success template"><i className="fas fa-plus mr-2" />Template</button>
                        </ModalToggler>
                    )}
                  
                    {sending ? (
                        <div style={{position: 'absolute', bottom: -7, right: 0, width: 50, height: 50}}>
                            <Circle />
                        </div>
                    ) : ''}
                </div>

            </div>
        )
    )
}


const styles = {
    loadingContainer: {
        position: 'absolute', 
        top: 0, 
        left: 0, 
        right: 0, 
        bottom: 0, background: 'rgba(0,0,0,.2)',
        zIndex: 100,
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center'
    }
}

export default SendPhone