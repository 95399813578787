import { useCallback } from "react";
import { Card, CardBody, CardFooter, CardHeader, CardTitle } from "reactstrap";

import SearchCollections from "components/system/Search/Collections";

import Circle from 'components/markup/loading/Circle';

const MattersCreateAssignRoles = ({setView,  workflowRoles, roles, setRoles, division}) => {

    const onMoveOn = useCallback(() => {
        if(!roles.length) return;
        setView('finish')
    }, [roles, setView])


    if(!workflowRoles) return <Circle />
    
    return (
        <div>

            <Card>
                <CardHeader>
                    <CardTitle className="mb-0"> Assign Matter Roles</CardTitle>
                </CardHeader>
                {!roles.length ? (
                    <CardBody>
                        <div className="alert alert-warning">At least one role below must be set before creating this case.</div>
                    </CardBody>
                ) : ''}
                <CardBody className="form-control-label-block">

                    {workflowRoles.map((workflowRole, i) => {
                        const found = roles.find(p => p.workflow_role === workflowRole._id)

                        let key = workflowRole._id
                        if(found) key += found.user

                        return (
                            <SearchCollections
                                key={key}
                                collection={'users'}
                                title={(
                                    <span className=" d-block">
                                        {workflowRole.name} 
                                        {found && found.user ? 
                                            <i style={{fontSize: 18}} className="float-right font-weight-bold fas fa-check-circle ml-2 text-success " /> :  
                                            <i style={{fontSize: 18}} className="float-right font-weight-bold fas fa-times-circle ml-2 text-danger " />} 
                                        </span>
                                )}
                                value={found ? found.user : ''}
                                onChange={(obj) => {
                                    const _roles = JSON.parse(JSON.stringify(roles))
                                    const foundIndex = _roles.findIndex(r => r.workflow_role === workflowRole._id)

                                    if(foundIndex !== -1) {
                                        _roles[foundIndex].user = obj.value
                                    } else {
                                        _roles.push({
                                            user: obj.value,
                                            workflow_role: workflowRole._id,
                                        })
                                    }

                                    setRoles(_roles)
                                }}
                                filter={{
                                    division
                                }}
                            /> 
                        )
                    })}
                
                </CardBody>
            
                <CardFooter className="text-right">
                    <button onClick={onMoveOn} className="btn btn-success">Move On <i className="fas fa-arrow-right" /></button>
                </CardFooter>
            </Card>

        </div>
    )

}

export default MattersCreateAssignRoles;