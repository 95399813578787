import { useCallback, useState } from "react";
import { Progress } from "reactstrap";

import ApiError from "components/markup/layout/ApiError";

import api from 'api';
import { toggleStandardLoader } from 'store/functions/system/system';

import Divisions from './Divisions'
import Workflows from './Workflows'
import Finish from './Finish'
import AssignContacts from './AssignContacts'
import AssignRoles from './AssignRoles'

const MattersCreate = ({onCreated}) => {

    const [workflowRoles, setWorkflowRoles] = useState(null);
    const [workflowContacts, setWorkflowContacts] = useState([]);

    const [workflow, setWorkflow] = useState('');
    const [division, setDivision] = useState('');
    const [parties, setParties] = useState([]);
    const [name, setName] = useState('');
    const [roles, setRoles] = useState([]);
    const [view, setView] = useState('divisions');
    const [err, setErr] = useState('');

    const onSetDivision = useCallback((_divisions) => {
        setDivision(_divisions)
        setView('workflows');
    }, [])

    const fetchWorkflowContacts = useCallback(async (workflow_id, division_id) => {
        // reset and fetch roles for the workflow
        setWorkflowContacts([])
        const _contacts = await api.workflow_contacts.find(workflow_id)
        if(_contacts.data) setWorkflowContacts(_contacts.data.sort((a, b) => a.name < b.name ? -1 : 1))
    }, [])

    const fetchWorkflowRoles = useCallback(async (workflow_id) => {
        // reset and fetch roles for the workflow
        setWorkflowRoles(null)
        const _roles = await api.workflow_roles.find(workflow_id)
        if(_roles.data) setWorkflowRoles(_roles.data.sort((a, b) => a.name < b.name ? -1 : 1))
    }, [])

    const onSetWorkflow = useCallback(async (_workflow) => {
        setWorkflow(_workflow)
        setView('assignContacts');

        fetchWorkflowContacts(_workflow._id, _workflow.division)
        fetchWorkflowRoles(_workflow._id, _workflow.division)
    }, [fetchWorkflowRoles, fetchWorkflowContacts,])
    
    const onCreate = useCallback(async () => {
        if(!name) return setErr([`A matter name must be entered before proceeding.`])

        const _parties = parties.filter(p => p.contact)
        const _roles = roles.filter(p => p.user)

        toggleStandardLoader(true)
        const created = await api.matters.create({
            workflow: workflow._id,
            division: division._id,
            name,
            parties: _parties,
            roles: _roles
        })
        toggleStandardLoader(false)
        if(!created.success) return setErr(created.message)
        onCreated(created.data)
    }, [workflow._id, division._id, name, onCreated, parties, roles])

    const progress = view === 'finish' ? 95 : view === 'assignRoles' ? 80 : view === 'assignContacts' ? 60 : view === 'contacts' ? 40 : view === 'workflows' ? 20 : 5;

    return (

        <>

            {err ? <ApiError err={err} color="danger" /> : '' }
       
            <div className="progress-wrapper pt-3 mt-0 mb-4">
                <div className="progress-info">
                    <div className="progress-label">
                        <span>Matter Creation</span>
                    </div>
                    <div className="progress-percentage">
                        {view === 'divisions' ? null : (
                            <button 
                                onClick={() => setView(
                                    view === 'finish' ? 'assignRoles' : 
                                    view === 'assignRoles' ? 'assignContacts' : 
                                    view === 'assignContacts' ? 'contacts' : 
                                    view === 'contacts' ? 'workflows' : 
                                    'divisions'
                                    )} 
                                className="btn btn-outline-warning"
                            >
                                <i className="fas fa-arrow-left mr-2 " />{' '}
                                {
                                    view === 'finish' ? 'Assign Roles' : 
                                    view === 'assignRoles' ? 'Assign Contacts' : 
                                    view === 'assignContacts' ? 'contacts' : 
                                    view === 'contacts' ? 'workflows' : 
                                    'Divisions'
                                }
                            </button>
                        )}
                    </div>
                </div>
                <Progress max="100" value={progress} color="" />
            </div>

            {view === 'divisions' ? (
                <Divisions 
                    onSetDivision={onSetDivision}
                />
            ) : view === 'workflows' ? (
                <Workflows 
                    division={division}
                    onSetWorkflow={onSetWorkflow}
                />
            ) : view === 'assignContacts' ? (
                <AssignContacts 
                    division={division}
                    workflowContacts={workflowContacts}
                    parties={parties}
                    setParties={setParties}
                    setView={setView}
                    setName={setName}
                />
            ): view === 'assignRoles' ? (
                <AssignRoles 
                    workflowRoles={workflowRoles}
                    roles={roles}
                    setRoles={setRoles}
                    setView={setView}
                />
            ) : view === 'finish' ? (
                <Finish 
                    division={division}
                    workflow={workflow}
                    name={name}
                    parties={parties}
                    roles={roles}
                    setName={setName}
                    onCreate={onCreate}
                    workflowContacts={workflowContacts}
                    workflowRoles={workflowRoles}
                />
            ) : ''}
            
        </>
    )

}

export default MattersCreate;