import { connect } from 'react-redux';
import { Card, CardFooter, CardHeader, CardTitle, Col, FormGroup, Input, Row } from 'reactstrap';

import ReactSelect from 'components/functional/inputs/ReactSelect';

import DatePicker from 'components/markup/inputs/Day';

import moment from 'moment';


const ModuleBKOverview = ({ data, onInputChange }) => {

    return (
        <div>
              <Card>
                <CardHeader>
                    <CardTitle className="mb- text-uppercase">Filing Fee {data.chapter === 13 ? ' & Payment History' : ''}</CardTitle>
                </CardHeader>

                {/* {data.chapter === 13 ? (

                ) : ''} */}

                <CardFooter>
                    <Row>
                        <Col xs={6}>
                            <FormGroup>
                                <label className='form-control-label'>Filing Fee Balance Due</label>
                                <Input 
                                    type="number"
                                    value={data.filing_fee_balance}
                                    onChange={e => onInputChange('filing_fee_balance', parseFloat(e.target.value))}
                                />
                            </FormGroup>

                        </Col>
                        <Col xs={6}>
                            <DatePicker 
                                title={`Filing Fee Payment Deadline`}
                                value={data.filing_fee_deadline ? moment(data.filing_fee_deadline).toDate() : ''} 
                                onChange={day => onInputChange('filing_fee_deadline', moment(day).format('MM/DD/YYYY'))} 
                            />
                        </Col>
                    </Row>

                    {data.chapter === 13 ? (
                        <div>
                            <Row>
                                <Col xs={6}>
                                    <FormGroup>
                                        <label className='form-control-label'>Payment History</label>
                                        <Input 
                                            type="number"
                                            value={data.payment_history}
                                            onChange={e => onInputChange('payment_history', parseFloat(e.target.value))}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={6}>
                                    <FormGroup>
                                        <label className='form-control-label'>Current Amount Delinquent</label>
                                        <Input 
                                            type="number"
                                            value={data.payment_delinquent_amount}
                                            onChange={e => onInputChange('payment_delinquent_amount', parseFloat(e.target.value))}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <ReactSelect
                                title="Payment Order Working?"
                                formGroup={true}
                                onChange={(obj) => onInputChange('payment_order_working', obj.value)}
                                options={[
                                    { value: false, label: 'No' },
                                    { value: true, label: 'Yes' },
                                ]}
                                value={data.payment_order_working}
                            /> 
                        </div>
                    ) : ''}

                </CardFooter>


            </Card>
        </div>


    )
}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(ModuleBKOverview);


