import { connect } from 'react-redux';
import { Card, CardFooter, CardHeader, CardTitle, FormGroup,  } from 'reactstrap';

const ModuleBKOverview = ({ data,  }) => {
    const hearings = data.hearing_results ? data.hearing_results.filter(r => r.type === '341') : []
    const recentHearing = hearings.length ? hearings[hearings.length - 1 ] : ''

    return (
        <div>
              <Card>
                <CardHeader>
                    <CardTitle className="mb-0 text-uppercase">Meeting Of Creditors</CardTitle>
                </CardHeader>

                <CardFooter>

                    <label className='form-control-label'>
                        {recentHearing && recentHearing.outcome === 'concluded' ? (
                            <i className="fas fa-check text-success mr-2" />
                        ) : (
                            <i className="fas fa-times text-danger mr-2" />
                        )}
                         341 Held / Concluded
                    </label>


                </CardFooter>

                {recentHearing && recentHearing.notes ? (
                    <CardFooter> 
                        <FormGroup>
                            <label className='form-control-label'>Attorney Notes</label>
    
                            <div className='p-3 border bg-secondary'>
                                <p className="text-sm mb-0"><span>{recentHearing.notes}</span></p>
                            </div>
                        </FormGroup>
                    </CardFooter>
                ) : ''}
              

                <CardFooter>
                    <p className='text-sm mb-0'><i className="fas fa-info-circle mr-2 text-info" /> To update this section, enter the 341 hearing results under the 341 Post Filed section.</p>
                </CardFooter>

            </Card>
        </div>


    )
}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(ModuleBKOverview);


